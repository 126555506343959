import { Link } from 'react-router-dom'
import { useState } from 'react'
import ContentSection from './ContentSection'
import ProfileCard from './ProfileCard'
import TimelineCard from './TimelineCard'
import VisionDescription from './VisionDescription'

const claims = [
  { q: 'What is Climb?', a: 'Climb is an innovative e-learning platform for corporate training.' },
  { q: 'How does Climb enhance learning?', a: 'Climb uses immersive, dynamic, and interactive experiences to engage learners.' },
  { q: 'What is the philosophy behind Climb?', a: 'Climb believes in learning by doing, providing hands-on experiences for true understanding.' },
  { q: 'Why choose Climb for corporate training?', a: 'Climb transforms training into a captivating and impactful experience.' },
  { q: 'How can Climb boost productivity?', a: 'Climb\'s engaging and interactive approach enhances learning outcomes and productivity.' },
  { q: 'Who can benefit from Climb?', a: 'Climb is designed for enterprises looking to improve their training programs.' }
]
const chosenClaim = claims[Math.floor(Math.random() * claims.length)]

export default function App () {
  const sections = {
    Demo: (key) => {
      return (
        <ContentSection key={key} title={['Try our', 'Demo']}>
          <div className='relative h-[50vh] bg-fixed bg-cover bg-center' style={{ backgroundImage: 'url(\'/images/demo.png\')' }}>
            <div className='absolute inset-0 bg-white/30 dark:bg-black/30 flex flex-col items-center justify-center text-center text-white px-4'>
              <Link to='/demo' target='_blank'>
                <button type='button' className='bg-accent-500 hover:bg-accent-600 dark:hover:bg-accent-400 focus:ring-accent-400 dark:focus:ring-accent-600 py-2.5 p-5 rounded-lg font-bold focus:outline-none focus:ring-4 text-xl'>
                  <div className='flex items-center'>Let's go!</div>
                </button>
              </Link>
            </div>
          </div>
        </ContentSection>
      )
    },
    Vision: (key) => {
      return (
        <ContentSection key={key} title={['Embrace our', 'Vision']}>
          <div class='flex flex-col gap-10'>
            <VisionDescription
              title='The Challenge of Modern Training'
              content="In a rapidly evolving business landscape, training has become more than just a necessity — it's a fascinating challenge. Videolessons you are currently using just aren't cutting it."
            />
            <VisionDescription
              title='The Future of Corporate Learning'
              content='At Climb, we believe that the future of corporate learning lies in immersive, dynamic, and interactive experiences that engage employees and foster real growth.'
            />
            <VisionDescription
              title='Learning by Doing'
              content="Our approach is rooted in the philosophy of <i>learning by doing</i>. We understand that true understanding and skill mastery come from hands-on experience.<br />That's why we've created a platform where users don't just watch and listen — they actively participate in their learning journey."
            />
            <VisionDescription
              title='Engagement Through Gamification'
              content='We believe that learning should be engaging and interactive.<br />By incorporating game-based learning principles, we create an environment where users are motivated to solve challenges, interact with AI-driven characters, and test their knowledge in real-world simulations.<br />This approach not only boosts engagement but also ensures that the skills learned are immediately applicable.'
            />
            <VisionDescription
              title='Transforming Corporate Training'
              content="At Climb, we're not just reimagining training — we're transforming it.<br />Join us in making learning a captivating and impactful experience for your enterprise."
            />
          </div>
        </ContentSection>
      )
    },
    Updates: (key) => {
      return (
        <ContentSection key={key} title={['Explore our', 'Updates']}>
          <ol className='relative flex flex-col gap-10 border-l ml-1.5'>
            <TimelineCard author='Lorenzo' date='2023-08-12' comment='OMG! We drafted our first game design document, what a crazy accomplishment!' />
            <TimelineCard author='Mattia' date='2024-08-11' comment="We finally completed our Demo: it was fun trying to integrate the systems and finding the most suitable setting. Having spent every free minute developing, Lorenzo won't be tanned this year either." />
            <TimelineCard author='Andrea' date='2024-09-02' comment='Pitch finished! We created a short slide deck which easily explains how Climb works. Do you want to read it? Write us!' />
            <TimelineCard author='Lorenzo' date='2024-09-06' comment="Site up & running. We have worked on it to explain our Vision and make it easy for everyone to try our Demo. But above else, because we like to look at Andrea's photo again and again, HOW BEAUTIFUL IS HE!?" />
          </ol>
        </ContentSection>
      )
    },
    Team: (key) => {
      return (
        <ContentSection key={key} title={['Meet our', 'Team']}>
          <div className='grid grid-rows-4 sm:grid-rows-none sm:grid-cols-2 lg:grid-cols-4 gap-5'>
            <ProfileCard name='Carlo Giuliano' title='Founder @ PixLex' description='Lawyer expert in fintech and gaming, through his legal firm Carlo helped several startups to grow and raise investments.' />
            <ProfileCard name='Andrea Galbiati' title='Product Design Director @ Docebo Inc' description='Product innovator with an extensive experience leading international teams and creating new desing solutions.' />
            <ProfileCard name='Lorenzo Rizzati' title='Cybersecurity Engineer @ TIM' description='DevOps engineer with a consolidated international experience in software development and project management.' />
            <ProfileCard name='Mattia Perucca' title='Marketing Manager @ Banca Progetto' description='Marketing and sales expert with a proven track record of driving growth for Fintech startups and emerging companies.' />
          </div>
        </ContentSection>
      )
    }
  }

  const [themeDropdownOpen, setThemeDropdownOpen] = useState(false)
  const toggleThemeDropdown = () => {
    setThemeDropdownOpen(!themeDropdownOpen)
  }
  const themeIcons = {
    light: [
      <path key='0' d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z' />,
      <path key='1' d='M12 4v1M17.66 6.344l-.828.828M20.005 12.004h-1M17.66 17.664l-.828-.828M12 20.01V19M6.34 17.664l.835-.836M3.995 12.004h1.01M6 6l.835.836' />
    ],
    dark: [
      <path key='0' fillRule='evenodd' clipRule='evenodd' d='M17.715 15.15A6.5 6.5 0 0 1 9 6.035C6.106 6.922 4 9.645 4 12.867c0 3.94 3.153 7.136 7.042 7.136 3.101 0 5.734-2.032 6.673-4.853Z' />,
      <path key='1' fillRule='evenodd' clipRule='evenodd' d='M17 3a1 1 0 0 1 1 1 2 2 0 0 0 2 2 1 1 0 1 1 0 2 2 2 0 0 0-2 2 1 1 0 1 1-2 0 2 2 0 0 0-2-2 1 1 0 1 1 0-2 2 2 0 0 0 2-2 1 1 0 0 1 1-1Z' />
    ],
    system: [
      <path key='0' d='M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6Z' />,
      <path key='1' d='M14 15c0 3 2 5 2 5H8s2-2 2-5' />
    ]
  }
  const [theme, setTheme] = useState(window.getCookie('theme'))
  const activateTheme = (a) => {
    window.restoreTheme(a)
    document.cookie = `theme=${a}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Strict; path=/`
    setTheme(a)
    toggleThemeDropdown()
  }

  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    setThemeDropdownOpen(false)
  }

  return (
    <div className='text-black bg-white dark:text-white dark:bg-black break-word'>
      <header className='bg-white dark:bg-black fixed w-full h-20 z-20 top-0 left-0 border-b p-5'>
        <nav className='max-w-screen-xl h-full flex items-center justify-between mx-auto'>
          <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt='Logo' className='h-full z-30 drop-shadow' />
          <div className={`flex flex-col sm:flex-row items-center justify-center sm:justify-between gap-5 transition-transform sm:translate-y-0 fixed sm:relative inset-0 backdrop-blur-md sm:backdrop-blur-none ${menuOpen ? '' : '-translate-y-full'}`}>
            <div className={`relative inline-flex items-center ${menuOpen ? 'flex-col-reverse' : 'flex-col'}`}>
              <button type='button' title='Change theme' className='hover:text-accent-500 drop-shadow' onClick={(toggleThemeDropdown)}>
                <svg viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='size-6'>
                  {themeIcons[theme]}
                </svg>
              </button>
              {themeDropdownOpen && (
                <div className={`absolute py-2 rounded-lg border bg-white dark:bg-black ${menuOpen ? 'bottom-7' : 'top-7'}`}>
                  {Object.entries(themeIcons).map((e, index) => (
                    <div key={index} className={`p-2 flex items-center justify-start gap-2 cursor-pointer hover:bg-neutral-100 dark:hover:bg-neutral-900 ${theme === e[0] ? 'text-accent-500' : ''}`} onClick={() => activateTheme(e[0])}>
                      <svg viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' className='size-6'>
                        {e[1]}
                      </svg>
                      {e[0].charAt(0).toUpperCase() + e[0].slice(1)}
                    </div>
                  )
                  )}
                </div>
              )}
            </div>

            {Object.keys(sections).map((sectionKey, index) => (<a key={index} href={'#' + sectionKey.toLowerCase()} className='text-xl font-bold hover:text-accent-500 drop-shadow' onClick={menuOpen ? toggleMenu : () => { setThemeDropdownOpen(false) }}>{sectionKey}</a>))}
          </div>
          <button className='block sm:hidden' onClick={toggleMenu}>
            <svg className={`size-5 drop-shadow transition-transform ${menuOpen ? 'rotate-90' : ''}`} fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
            </svg>
          </button>
        </nav>
      </header>

      <main className='max-w-screen-xl mx-auto px-5 py-40 flex flex-col gap-40'>
        <section id='hero' className='flex flex-col justify-between gap-10'>
          <h1 className='text-5xl sm:text-7xl lg:text-9xl font-bold drop-shadow animate-[bounce_1s_ease-out_1.5]'>
            {chosenClaim.q}
          </h1>
          <h2 className='text-3xl sm:text-5xl lg:text-7xl font-bold text-accent-500'>
            {chosenClaim.a}
          </h2>
        </section>
        {Object.values(sections).map((sectionValue, index) => sectionValue(index))}
      </main>

      <footer className='max-w-screen-xl mx-auto p-5 text-sm font-light flex flex-col items-center justify-between gap-10'>
        <div className='w-full flex flex-col sm:flex-row items-center sm:items-end justify-between gap-10'>
          <img src={`${process.env.PUBLIC_URL}/logomark.svg`} alt='Logomark' className='size-20 drop-shadow' />
          <div className='flex items-center justify-between gap-5'>
            <a href='#' className='hover:underline'>About</a>
            <a href='#' className='hover:underline'>Privacy Policy</a>
            <a href='#' className='hover:underline'>Licensing</a>
            <a href='mailto:founders@climb2learn.com' className='hover:underline'>Contact</a>
          </div>
        </div>
        <hr className='w-full' />
        <span>© 2023 <Link to='/' className='hover:underline'>Climb2learn™</Link>. All rights reserved.</span>
      </footer>
    </div>
  )
}
